@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
}

.App {
  text-align: center;
  color: #333;
}

.App-logo {
  height: 25%;
  margin: 20px;
}

.App-header {
  background-color: #282c34;
  padding: 40px 20px;
  color: white;
  text-align: left;
  height: 80vh;
}

.App-header h1 {
  font-weight: 700;
  font-size: 2.5em;
  margin: 0;
}

.App-header p {
  font-weight: 300;
  font-size: 1.2em;
  margin: 10px 0 0 0;
}

.App-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}


.App-nav {
  margin: 20px 0;
}

.App-nav-link {
  margin: 0 10px;
  color: #ff6f61;
  text-decoration: none;
  font-size: 1.1em;
  transition: color 0.3s;
  position: relative;
}

.App-nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #ff6f61;
  transition: width 0.3s;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.App-nav-link:hover::after,
.App-nav-link.active::after {
  width: 100%;
}

.App-nav-link:hover {
  color: #e65a50;
}

.Navbar {
  background-color: #282c34;
  padding: 20px;
  color: white;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  a{
    display: inline-flex;
  }
}

.logo-link {
  height: 7vh;
}

.Introduction, .Founders {
  padding: 60px 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  height: 100vh;
}

.Contact {
  padding: 60px 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.Introduction h2, .Founders h2, .Contact h2 {
  font-weight: 700;
  font-size: 2em;
  margin-bottom: 20px;
}

.Introduction p, .Founders p, .Contact p {
  font-weight: 300;
  font-size: 1.1em;
  line-height: 1.6;
}

.Founders .founder {
  margin-top: 20px;
}

.Interest-button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin: 40px 0;
}

.Interest-button:hover {
  background-color: #e65a50;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 40px;
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.Overlay {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
}

.Modal h2 {
  font-weight: 700;
  font-size: 1.8em;
  margin-bottom: 20px;
}

/* Modern and stylish form styles */
.form {
  display: flex;
  flex-direction: column;
}

.form-label {
  font-weight: 500;
  font-size: 1.1em;
  margin-bottom: 8px;
  color: #333;
}

.form-input, .form-textarea, .form-select {
  padding: 12px 15px;
  font-size: 1em;
  border: 2px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s, box-shadow 0.3s;
  margin-bottom: 20px;
}

.form-input:focus, .form-textarea:focus, .form-select:focus {
  border-color: #ff6f61;
  box-shadow: 0 0 5px rgba(255, 111, 97, 0.5);
  outline: none;
}

.form-textarea {
  resize: vertical;
  min-height: 100px;
}

.form-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.form-checkbox-label {
  margin-right: 20px;
}

.form-button {
  padding: 10px 20px;
  font-size: 1em;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ff6f61;
  color: white;
  transition: background-color 0.3s, transform 0.3s;
}

.form-button:hover {
  background-color: #e65a50;
  transform: translateY(-2px);
}

.form-button.close-button {
  background-color: #ccc;
  color: #333;
}

.form-button.close-button:hover {
  background-color: #bbb;
}


.interest-button {
  background: linear-gradient(45deg, #ff6f61, #ff8a50);
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.interest-button:hover {
  background: linear-gradient(45deg, #ff8a50, #ff6f61);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}